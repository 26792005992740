import * as React from "react";
import AboutHero from "../sections/AboutHero/AboutHero";
import FAQs from "../sections/FAQs/FAQs";
import Footer from "../sections/Footer/Footer";
import { Helmet } from "react-helmet";
import Navbar from "../sections/Navbar/Navbar";
import OurVision from "../sections/OurVision/OurVision";
import Team from "../sections/Team/Team";
import "../fonts/stylesheet.css";

// styles
const text = {
  fontFamily: "Manrope",
};

// markup
const FaqPage = () => {
  return (
    <main style={text}>
      {" "}
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Conquest: India's First and Largest Student-run Startup Accelerator
        </title>
      </Helmet>
      <Navbar />
      <FAQs />
      <Footer />
    </main>
  );
};

export default FaqPage;
